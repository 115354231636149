<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
             <router-link to="/schentrance" class="text-white">
             <span class="text-white"><i class="ni ni-bold-right"></i>Entrance </span></router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>Entrance Result</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="table-responsive mt-2">
                    <table class="table table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Student Name</th>
                                <th>Total Attempt</th>
                                <th>Score</th>                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in resultitem" :key="item.Id">
                                <td>{{ item.StudentName }}</td>
                                <td>{{ item.TotalAttempt }}</td>
                                <td>
                                    {{item.CorrectAnswer}}
                                </td>                               
                            </tr>
                        </tbody>
                    </table>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import swal from 'sweetalert'
export default {
    name: 'studentanswer',
    data() {
        return {
            accessDetails:null,
            HeaderId: localStorage.getItem('SchentranceheaderId') || '',           
            resultitem: null,
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {      
        getresult: function () {
            let Id = this.HeaderId;           
            this.onloader = 'flex'
            this.$store.dispatch('schentranceexamresult', Id)
                .then((resp) => {
                    this.resultitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
       
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        if (this.accessDetails.isSchoolStaff == false) {
            this.$store.dispatch("auth/Logout");
            this.$router.push("/login");
        } else {
            if (this.$store.state.schooldata.schoolinfo.StatusId == 10) {
                this.$router.push("/tccbtheader");
            } else {
                this.getresult();
            }
        }
        
    }
};
</script>

<style></style>
