<template>
  <div class="row" v-if="staffDetails && accessDetails">
    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isPortalAdmin==true">
      <router-link to="/school/session-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted text-white mb-0">Session</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div
      class="col-xs-3 m-2 shrink">
      <router-link to="/school/term-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted text-white mb-0">Term Close/Open</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/class-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Class</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/option-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Option</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/arm-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Arm</h5>
          </div>
        </div>
      </router-link>
    </div>

    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/subject-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Subject</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/mock-exam-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Mock Exam</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/mock-exam-subject-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Mock Subject</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/psycomotor-skill-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">
              Psycomotor Skill
            </h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/conduct-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Conduct</h5>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/grade-scale-settings">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">Grade Scale</h5>
          </div>
        </div>
      </router-link>
    </div>
     
    <div class="col-xs-3 m-2 shrink">
      <router-link to="/school/details">
        <div class="card bg-gradient-success">
          <div class="card-body text-center">
            <div
              class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2"
            >
              <i class="ni ni-folder-17"></i>
            </div>
            <h5 class="card-title text-muted mb-0 text-white">
              School Details
            </h5>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {    
      accessDetails: null,
      staffDetails: null,
      onloader: "none",
    };
  },
  mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        this.staffDetails = this.$store.getters['SchoolStaff/_schoolStaff'];
    },
};
</script>