<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white">
        Dashboard</router-link>
      <router-link to="/schcbtmenu" class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>CBT</router-link>
      <router-link to="/school/exam-setup" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>CBT Question
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>CBT Question Details
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-7">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-secondary">
                        <div class="card-body">
                          <div v-if="cbtheaderitem" class="card bg-info">
                            <div class="card-body">
                              <h2>
                                Access Code :
                                <b>{{ cbtheaderitem.PassCode }}</b>
                              </h2>
                              <div class="row">
                                <div class="col-md-6">
                                  <span><b>Session : </b>
                                    {{ cbtheaderitem.SchSession }}</span>
                                </div>
                                <div class="col-md-6">
                                  <span><b>Term : </b>
                                    {{ cbtheaderitem.SchoolTerm }}</span>
                                </div>
                                <div class="col-md-6">
                                  <span><b>Class : </b>
                                    {{ cbtheaderitem.Sch_Class }}</span>
                                </div>
                                <div class="col-md-6">
                                  <span><b>Option : </b>
                                    {{ cbtheaderitem.ClassOption }}</span>
                                </div>
                                <div class="col-md-6">
                                  <span><b>Subject : </b>
                                    {{ cbtheaderitem.SubjectName }}</span>
                                </div>
                                <div class="col-md-6">
                                  <span><b>No. to Display : </b>
                                    {{
      cbtheaderitem.NoofQuestiontoDisplay
    }}</span>
                                </div>
                                <div class="col-md-6">
                                  <span><b>Exam Period : </b>
                                    {{ cbtheaderitem.ExamPeriod }}s</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>Question</label>
                            <quillEditor v-model="ExamQuestionval" ref="ExamQuestionval" :options="editorOption">

                            </quillEditor>

                          </div>
                          <div class="form-group">
                            <label>Answer Details</label>
                            <quillEditor v-model="AnswerDetailsval" ref="AnswerDetailsval" :options="editorOption">

                            </quillEditor>

                          </div>

                          <div class="row">
                            <div class="col-md-8">
                              <div class="form-group">
                                <label>Option A</label>
                                <quillEditor v-model="OptionAval" ref="OptionAval" :options="editorOption">

                                </quillEditor>

                              </div>
                            </div>
                            <div class="col-md-4">
                              <base-checkbox class="mt-6 mb-4 ml-4" v-model="IsAnswerAval" @change="checkcheck()">
                                Is A Answer
                              </base-checkbox>
                            </div>
                            <div class="col-md-8">
                              <div class="form-group">
                                <label>Option B</label>
                                <quillEditor v-model="OptionBval" ref="OptionBval" :options="editorOption">

                                </quillEditor>

                              </div>
                            </div>
                            <div class="col-md-4">
                              <base-checkbox class="mt-6 mb-6 ml-4" v-model="IsAnswerBval" @change="checkcheck()">
                                Is B Answer
                              </base-checkbox>
                            </div>
                            <div class="col-md-8">
                              <div class="form-group">
                                <label>Option C</label>
                                <quillEditor v-model="OptionCval" ref="OptionCval" :options="editorOption">

                                </quillEditor>

                              </div>
                            </div>
                            <div class="col-md-4">
                              <base-checkbox class="mt-6 mb-6 ml-4" v-model="IsAnswerCval" @change="checkcheck()">
                                Is C Answer
                              </base-checkbox>
                            </div>
                            <div class="col-md-8">
                              <div class="form-group">
                                <label>Option D</label>
                                <quillEditor v-model="OptionDval" ref="OptionDval" :options="editorOption">

                                </quillEditor>

                              </div>
                            </div>
                            <div class="col-md-4">
                              <base-checkbox class="mt-6 mb-6 ml-4" v-model="IsAnswerDval" @change="checkcheck()">
                                Is D Answer
                              </base-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="mt-2">
                <div class="card bg-secondary my-2" v-for="(item, index) in cbtquestionitem" :key="item.Id">
                  <div class="card-body">
                    <span class="badge badge-warning">
                      Qtn. {{ index + 1 }}</span>
                    <div class="my-3" v-html="item.Question"></div>
                    <div class="m-3">
                      <div class="row">
                        <div class="col-lg-1">
                          <span v-if="item.OptionAIsAnswer" class="badge badge-success">A</span>
                          <span v-else class="badge badge-dark">A</span>
                        </div>

                        <div class="col-lg-11" v-html="item.OptionA"></div>
                      </div>
                      <div class="row">
                        <div class="col-lg-1">
                          <span v-if="item.OptionBIsAnswer" class="badge badge-success">B</span>
                          <span v-else class="badge badge-dark">B</span>
                        </div>
                        <div class="col-lg-11" v-html="item.OptionB"></div>
                      </div>
                      <div class="row">
                        <div class="col-lg-1">
                          <span v-if="item.OptionCIsAnswer" class="badge badge-success">C</span>
                          <span v-else class="badge badge-dark">C</span>
                        </div>
                        <div class="col-lg-11" v-html="item.OptionC"></div>
                      </div>
                      <div class="row">
                        <div class="col-lg-1">
                          <span v-if="item.OptionDIsAnswer" class="badge badge-success">D</span>
                          <span v-else class="badge badge-dark">D</span>
                        </div>
                        <div class="col-lg-11" v-html="item.OptionD"></div>
                      </div>
                      <div class="bg-translucent-secondary m-3 p-3">
                        <b class="text-success">Answer Details</b>
                        <div v-html="item.AnswerDetails"></div>
                      </div>
                    </div>
                    <div class="card-footer text-center">
                      <button class="btn btn-secondary btn-sm my-2" v-on:click="edititem(item.QuestionId)">
                        <i class="fa fa-edit"></i> Edit
                      </button>
                      <button class="btn btn-secondary btn-sm my-2" v-on:click="sharequestion(item.QuestionId)">
                        <i class="fa fa-share"></i> Share
                      </button>
                      <button class="btn btn-secondary btn-sm my-2" v-on:click="deleteschquestion(item.QuestionId)">
                        <i class="fa fa-times"></i> Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import PageSpinner from "@/components/PageSpinner";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "schcbtquestion",
  mixins: [CkEditorUploadAdapter],
  components: {
    PageSpinner,
    quillEditor
  },
  data() {
    return {
      accessDetails:null,
      Idval: 1,
      HeaderIdval: localStorage.getItem("SchcbtheaderId") || "",
      ExamQuestionval: "",
      AnswerDetailsval: "",
      ImagePathval: "",
      OptionAIdval: 1,
      OptionBIdval: 1,
      OptionCIdval: 1,
      OptionDIdval: 1,
      OptionAval: "",
      IsAnswerAval: false,
      OptionBval: "",
      IsAnswerBval: false,
      OptionCval: "",
      IsAnswerCval: false,
      OptionDval: "",
      IsAnswerDval: false,
      cbtquestionitem: null,
      cbtheaderitem: null,
      file: "",
      uploadedimage: "",
      process: "N",
      onloader: "none",
      editorOption: {
        debug: 'info',
        placeholder: 'Type in here',
        readonlly: true,
        theme: 'snow'
      },
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },
  methods: {
    checkcheck() {
      if (this.IsAnswerAval == true) {
        this.IsAnswerBval = false;
        this.IsAnswerCval = false;
        this.IsAnswerDval = false;
      }
      if (this.IsAnswerBval == true) {
        this.IsAnswerAval = false;
        this.IsAnswerCval = false;
        this.IsAnswerDval = false;
      }
      if (this.IsAnswerCval == true) {
        this.IsAnswerBval = false;
        this.IsAnswerAval = false;
        this.IsAnswerDval = false;
      }
      if (this.IsAnswerDval == true) {
        this.IsAnswerBval = false;
        this.IsAnswerCval = false;
        this.IsAnswerAval = false;
      }
    },
    newprocess() {
      this.ExamQuestionval = "";
      this.AnswerDetailsval = "";
      this.ImagePathval = null;
      this.uploadedimage = null;
      this.OptionAval = "";
      this.OptionBval = "";
      this.OptionCval = "";
      this.OptionDval = "";
      this.IsAnswerAval = false;
      this.IsAnswerBval = false;
      this.IsAnswerCval = false;
      this.IsAnswerDval = false;
      this.process = "N";
      this.openform = true;
    },
    closeprocess() {
      this.ExamQuestionval = "";
      this.AnswerDetailsval = "";
      this.ImagePathval = null;
      (this.uploadedimage = null), (this.OptionAval = "");
      this.OptionBval = "";
      this.OptionCval = "";
      this.OptionDval = "";
      this.IsAnswerAval = false;
      this.IsAnswerBval = false;
      this.IsAnswerCval = false;
      this.IsAnswerDval = false;
      this.process = "N";
      this.openform = false;
    },
    sharequestion(Id) {
      localStorage.setItem("QuestionId", Id);
      this.$router.push("/school/share-exam-question-setup");
    },
    edititem(Id) {
      this.Idval = Id;
      this.ExamQuestionval = "";
      this.AnswerDetailsval = "";
      this.ImagePathval = null;
      this.OptionAval = "";
      this.OptionBval = "";
      this.OptionCval = "";
      this.OptionDval = "";
      this.IsAnswerAval = false;
      this.IsAnswerBval = false;
      this.IsAnswerCval = false;
      this.IsAnswerDval = false;
      this.process = "U";
      this.onloader = "flex";
      this.$store
        .dispatch("getschquestionbyid", Id)
        .then((resp) => {
          if (resp != null) {
            this.ExamQuestionval = resp.Question;
            this.AnswerDetailsval = resp.AnswerDetails;
            this.ImagePathval = resp.ImagePath;
            if (resp.ImagePath != null) {
              this.uploadedimage =
                this.$store.state.auth.baseurl + resp.ImagePath.substr(1);
            }
            this.OptionAval = resp.OptionA;
            this.OptionBval = resp.OptionB;
            this.OptionCval = resp.OptionC;
            this.OptionDval = resp.OptionD;
            this.IsAnswerAval = resp.OptionAIsAnswer;
            this.IsAnswerBval = resp.OptionBIsAnswer;
            this.IsAnswerCval = resp.OptionCIsAnswer;
            this.IsAnswerDval = resp.OptionDIsAnswer;
            this.OptionAIdval = resp.OptionAId;
            this.OptionBIdval = resp.OptionBId;
            this.OptionCIdval = resp.OptionCId;
            this.OptionDIdval = resp.OptionDId;
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let QuestionId = this.Idval;
      let HeaderId = this.HeaderIdval;
      let ExamQuestion = this.ExamQuestionval;
      let AnswerDetails = this.AnswerDetailsval;
      let ImagePath = this.ImagePathval;
      let IdA = this.OptionAIdval;
      let IdB = this.OptionBIdval;
      let IdC = this.OptionCIdval;
      let IdD = this.OptionDIdval;
      let OptionA = this.OptionAval;
      let OptionB = this.OptionBval;
      let OptionC = this.OptionCval;
      let OptionD = this.OptionDval;
      let IsAnswerA = this.IsAnswerAval;
      let IsAnswerB = this.IsAnswerBval;
      let IsAnswerC = this.IsAnswerCval;
      let IsAnswerD = this.IsAnswerDval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        QuestionId,
        HeaderId,
        ExamQuestion,
        AnswerDetails,
        ImagePath,
        IdA,
        IdB,
        IdC,
        IdD,
        OptionA,
        OptionB,
        OptionC,
        OptionD,
        IsAnswerA,
        IsAnswerB,
        IsAnswerC,
        IsAnswerD,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processschquestion", userdata)
        .then((resp) => {
          this.cbtquestionitem = resp;
          this.closeprocess();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getschquestion() {
      let HeaderId = this.HeaderIdval;
      if (HeaderId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getschquestion", HeaderId)
          .then((resp) => {
            this.cbtquestionitem = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    deleteschquestion(QuestionId) {
      let HeaderId = this.HeaderIdval;
      if (HeaderId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("deleteschquestion", { QuestionId, HeaderId })
          .then((resp) => {
            this.cbtquestionitem = resp;
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Deleted Successfully",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getheader() {
      let HeaderId = this.HeaderIdval;
      if (HeaderId != null) {
        this.onloader = "flex";
        this.$store
          .dispatch("getschquestionheaderbyid", HeaderId)
          .then((resp) => {
            this.cbtheaderitem = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      } else {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },

    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch("auth/Logout");
      this.$router.push("/login");
    } else {
      if (this.$store.state.schooldata.schoolinfo.StatusId == 10) {
        this.$router.push("/tccbtheader");
      } else {
        this.getheader();
        this.getschquestion();
      }
    }

  },
};
</script>

<style></style>
